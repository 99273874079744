.card-wrapper {
    width: 100%;
    margin: 0px;
    gap: 1em;

    .card {
        background-color: var(--color-primary);
        text-align: center;
        color: var(--color-white);
        padding: 2em;
        border-radius: var(--effect-roundedCorners);
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .cta-id {
            margin-bottom: 2em;

            h1 {
                font-size: 1.4em;
            }

            h2 {
                font-size: 0.875em;
            }
        }
    }
}

/* ============= MEDIA QUERIES (MEDIUM DEVICES) ============= */

@media screen and (max-width: 1024px) {
    .card-wrapper {
        flex-direction: column;

        .btn {
            width: 100%;
        }
    }
}