footer {
    background-color: var(--color-dark);
    color: var(--color-white);

    #footerMain {
        padding: 3.5em 0;
        justify-content: space-between;
        align-items: baseline;
        gap: 0.5em;
        min-height: 235px;
        font-size: 14px;

        a {
            color: var(--color-primary-variant);
        }

        .copyLink {
            margin-left: 1em;
            font-size: 12px;
            display: flex;
            align-items: center;
            color: rgba(255, 255, 255, 0.5);
            cursor: pointer;
            transition: var(--transition-fast);

            .copyIcon {
                font-size: 13px;
                margin-right: 0.5em;
                color: var(--color-white);
            }

            &:hover {
                color: var(--color-white);
            }
        }

        .recentPosts {
            color: var(--color-primary-variant);

            ul {
                list-style-type: none;
                color: var(--color-white);

                li {
                    position: relative;
                    padding-left: 14px;

                    &:before {
                        position: absolute;
                        top: 9px;
                        left: 0;
                        border-width: 3px;
                        border-style: solid;
                        border-radius: 3px;
                        content: "";
                        border-color: var(--color-primary-variant);
                    }
                }
            }
        }

        .badges {
            align-self: flex-start;

            img {
                border-radius: var(--effect-roundedCorners);
            }
        }

        .nmls {
            p {
                line-height: 1.3;
            }
        }
    }

    #footerSocial {
        height: 60px;
        padding: .25em 2em;
        justify-content: flex-end;
        align-items: center;
        background-color: var(--color-darker);
        border-top-left-radius: var(--effect-roundedCorners);
        border-top-right-radius: var(--effect-roundedCorners);
        font-size: 2em;
        
        a {
            color: var(--color-text-light);
        }
        
        * {
            margin-bottom: -0.15em;
            margin-left: .25em;
            transition: var(--transition-fast);

            &:hover {
                color: var(--color-white);
            }
        }
    }
}

/* ============= MEDIA QUERIES (MEDIUM DEVICES) ============= */

@media screen and (max-width: 1024px) {
    footer {
        #footerMain {
            flex-direction: column;
            align-items: center;
            gap: 2.5em;

            * {
                text-align: center;
            }

            .badges {
                align-self: center;
            }
        }
    }
}

/* ============= MEDIA QUERIES (SMALL DEVICES) ============= */

@media screen and (max-width: 600px) {
    footer {
        #footerSocial {
            height: 130px;
            align-items: flex-start;
            
            * {
                margin-bottom: 0;
            }
        }
    }
}

/* ============= MEDIA QUERIES (EXTRA SMALL DEVICES) ============= */

@media screen and (max-width: 300px) {
    footer {
        #footerMain {
            .phone,
            .email {
                flex-direction: column;
                align-items: center;
            }
        }
    }
}