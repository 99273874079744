#aboutRoot p img {
  float: left;
  margin-right: 2em;
}

/* ============= MEDIA QUERIES (SMALL DEVICES) ============= */
@media screen and (max-width: 600px) {
  #aboutRoot p img {
    float: none;
    margin-right: 0;
    width: 100%;
    max-height: 200px;
    -o-object-fit: cover;
       object-fit: cover;
    margin-bottom: 2em;
  }
  #aboutContent {
    display: flex;
    flex-direction: column;
  }
}/*# sourceMappingURL=about.css.map */