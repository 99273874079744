#faqRoot .faq-wrapper .faq {
  scroll-margin-top: calc(2em + var(--nav-height));
  background-color: var(--color-primary-light);
  padding: 2em;
  margin: 2em 0;
  border-radius: var(--effect-roundedCorners);
  box-shadow: var(--effect-dropShadowSubtle);
}
#faqRoot .faq-wrapper .faq h2.question {
  font-size: 1.25em;
  margin-bottom: 1em;
}
#faqRoot .faq-wrapper .faq h2.question::before {
  content: "Q.";
  margin-right: 1em;
}
#faqRoot .faq-wrapper .faq p.answer {
  margin: 0;
}
#faqRoot .faq-wrapper .faq p.answer::before {
  content: "A.";
  margin-right: 1em;
}/*# sourceMappingURL=faq.css.map */