form#contact-form {
    display: flex;
    flex-direction: column;

    label {
        font-size: 1em;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding: 3px 0 15px 3px;
        font-weight: 700;
    }

    input,
    textarea {
        margin-bottom: 1.5rem;
        padding: 1.5em;
        border: none;
        outline: 0;
        background: #fff;
        -webkit-box-shadow: 0 2px 4px 0 rgba(14,30,37,.12);
        box-shadow: 0 2px 10px 0 rgba(14,30,37,.12);
        border-radius: 10px;
        width: 100%;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    textarea {
        min-height: 100px;
    }

    button[type="submit"] {
        font-weight: 800;
        font-size: 1.25em;
        text-transform: uppercase;
        padding: .5em 1em;
        border-radius: 10px;
        background-color: var(--color-primary);
        color: var(--color-white);

        &:hover {
            cursor: pointer;
        }
    }

    .hidden {
        display: none;
    }

    .form-row {
        display: flex;
        flex-direction: column;

        &.split {
            flex-direction: row;
            gap: 2em;

            .form-element {
                width: 50%;
            }
        }

        &.shrink {
            width: min-content;
        }
    }
}

/* ============= MEDIA QUERIES (SMALL DEVICES) ============= */

@media screen and (max-width: 600px) {
    form#contact-form {
        .form-row {
            &.split {
                flex-direction: column;
                gap: 0;

                .form-element {
                    width: unset;
                }
            }
        }
    }
}