#testimonialRoot {
    section.cardWrapper {
        all: unset;

        article.card {
            margin-bottom: 2em;
            padding: 2em;
            border-radius: var(--effect-roundedCorners);
            background-color: var(--color-primary-light);
            box-shadow: var(--effect-dropShadowSubtle);

            h3.header {
                font-size: 1.75em;
                font-weight: 700;
                line-height: 1;
                background: url(https://integra.imgix.net/quotes.png) no-repeat scroll left center transparent;
                padding-left: 40px;
            }

            .meta {
                line-height: 1;
                font-size: 14px;
                color: var(--color-text-lighter);
                margin: .5em 0;
                
                * {
                    display: block;
                }
            }

            .content {
                margin: 1em 0;
                font-size: 14px;
            }
        }
    }

    ul li::before {
        all: unset;
    }
}

/* ============= MEDIA QUERIES (SMALL DEVICES) ============= */

@media screen and (max-width: 600px) {
    #testimonialRoot {
        p img {
            float: none;
            margin-right: 0;
            width: 100%;
            max-height: 200px;
            object-fit: cover;
            margin-bottom: 2em;
        }
    }
    
    #testimonialContent {
        display: flex;
        flex-direction: column;
    }
}