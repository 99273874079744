#homeRoot {
  height: 100vh;
}

.home-background {
  background-image: url("https://integra.imgix.net/integra_house1_max.jpg?auto=compress&h=1080");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: -10;
}
.home-background .bg-overlay {
  background-color: #000;
  opacity: 0.5;
  height: 100%;
  width: 100%;
}

#homeTitle {
  padding-top: calc(150px + 0.4em);
  height: 100%;
  color: var(--color-white);
}
#homeTitle h1 {
  font-weight: 600;
  font-size: clamp(28px, 5vw, 45px);
}
#homeTitle h2 {
  width: 75%;
  font-size: clamp(16px, 5vw, 20px);
  font-style: italic;
}
#homeTitle .cta-wrapper {
  display: flex;
  margin-top: 1.5em;
}
#homeTitle .cta-wrapper * + * {
  margin-left: 1em;
}
#homeTitle .cta-wrapper button {
  display: flex;
  align-items: center;
}
#homeTitle .cta-wrapper button svg {
  font-size: 0;
  margin-left: 0;
  transition: var(--transition);
}
#homeTitle .cta-wrapper button:hover svg {
  font-size: 1.5em;
  margin-left: 0.25em;
}

#homeScrollIndicator {
  padding: 2em;
}
#homeScrollIndicator a.scrollDown {
  line-height: 0%;
}

#homeInfo {
  padding: 4em 0;
}
#homeInfo .text-block {
  text-align: center;
}
#homeInfo .text-block h1 {
  font-size: 1.375em;
  margin-bottom: 0.5em;
}
#homeInfo .text-block p {
  font-size: 0.875em;
}
#homeInfo .card-wrapper {
  margin-top: 4em;
}
#homeInfo .card-wrapper h2 {
  font-weight: 500;
}
#homeInfo .card-wrapper .btn a {
  color: white;
}

/* ============= MEDIA QUERIES (MEDIUM DEVICES) ============= */
@media screen and (max-width: 1024px) {
  #homeTitle h1 {
    font-weight: 600;
  }
  #homeTitle h2 {
    font-style: italic;
  }
  #homeTitle .cta-wrapper {
    display: flex;
    flex-direction: column;
  }
  #homeTitle .cta-wrapper * + * {
    margin-left: 0;
    margin-top: 0.75em;
  }
  #homeTitle .cta-wrapper button {
    display: flex;
    align-items: center;
  }
  #homeTitle .cta-wrapper button svg {
    font-size: 0;
    margin-left: 0;
    transition: var(--transition);
  }
  #homeTitle .cta-wrapper button:hover svg {
    font-size: 1.5em;
    margin-left: 0.25em;
  }
  #homeScrollIndicator {
    margin-bottom: 4em;
  }
}
/* ============= MEDIA QUERIES (SMALL DEVICES) ============= */
@media screen and (max-width: 600px) {
  #homeTitle h2 {
    width: 100%;
  }
}/*# sourceMappingURL=home.css.map */