#faqRoot {
    // .faq-tableOfContents {
    //     a {
    //         &:hover {
    //             color: red;
    //         }
    //     }
    // }

    .faq-wrapper {
        .faq {
            scroll-margin-top: calc(2em + var(--nav-height));
            background-color: var(--color-primary-light);
            padding: 2em;
            margin: 2em 0;
            border-radius: var(--effect-roundedCorners);
            box-shadow: var(--effect-dropShadowSubtle);

            h2.question {
                font-size: 1.25em;
                margin-bottom: 1em;

                &::before {
                    content: 'Q.';
                    margin-right: 1em;
                }
            }
            p.answer {
                margin: 0;

                &::before {
                    content: 'A.';
                    margin-right: 1em;
                }
            }
        }
    }
}