#calculatorRoot {
    .calculator-wrapper {
        form {
            margin: 0 2em;

            .form-item {
                margin: 1em 0;

                label {
                    font-weight: 600;
                    font-size: 1.25em;
                }

                .form-input {
                    input {
                        padding: 1em;
                        border-radius: var(--effect-roundedCorners);
                        box-shadow: var(--effect-dropShadowSubtle);
                    }
                }
            }

            .form-action {
                margin: 1em 0;
                
                input {
                    padding: 1em;
                    background-color: var(--color-primary-variant);
                    border-radius: var(--effect-roundedCorners);
                    box-shadow: var(--effect-dropShadowSubtle);
                    font-size: 1em;
                    font-weight: 600;
                    color: var(--color-white);
                    font-family: 'Poppins', sans-serif;
                    transition: var(--transition-fast);

                    &:hover {
                        cursor: pointer;
                        background-color: var(--color-primary-light);
                        color: var(--color-text-black);
                    }
                }
            }
        }

        .estimatedPayment {
            background-color: var(--color-primary-variant);
            margin: 4em;
            padding: 1em;
            text-align: center;
            border-radius: var(--effect-roundedCorners);
            box-shadow: var(--effect-dropShadowSubtle);
            min-width: 320px;

            span {
                color: white;
                font-size: 2.5em;
            }
        }

        .table-wrapper {
            overflow-x: auto;
            margin-top: 2em;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            background-color: var(--color-primary-light);
            border-radius: var(--effect-roundedCorners);
            box-shadow: var(--effect-dropShadowSubtle);

            thead {
                tr {
                    th {
                        padding: 1em 1em;
                    }
                }
            }

            tbody {
                tr {
                    border-bottom: 1px solid var(--color-border-grey);
                    text-align: center;

                    td {
                        padding: 1em 0;
                    }
                }
            }
        }
    }
}

/* ============= MEDIA QUERIES (LARGE DEVICES) ============= */

@media screen and (max-width: 1200px) {
    #calculatorRoot {
        .calculator-wrapper {
            .estimatedPayment {
                margin: 4em 2em;
                min-width: 280px;
        
                span {
                    font-size: 2.5em;
                }
            }
        }
    }
}

/* ============= MEDIA QUERIES (MEDIUM DEVICES) ============= */

@media screen and (max-width: 1024px) {
    #calculatorRoot {
        .calculator-wrapper {
            form {
                margin: 0 1em 0 0;
            }

            .estimatedPayment {
                margin: 4em 0em;
        
                span {
                    font-size: 1.75em;
                }
            }
        }
    }
}

/* ============= MEDIA QUERIES (INTERMEDIATE DEVICES) ============= */

@media screen and (max-width: 800px) {
    #calculatorRoot {
        .calculator-wrapper {
            form {
                margin: 0 2em;
            }

            .estimatedPayment {
                min-width: 250px;
            }
        }
    }
}

/* ============= MEDIA QUERIES (SMALL DEVICES) ============= */

@media screen and (max-width: 600px) {
    #calculatorRoot {
        .calculator-wrapper {
            .form-wrapper {
                flex-direction: column;

                form {
                    margin: 0;
                }
            }
        }
    }
}

/* ============= MEDIA QUERIES (EXTRA SMALL DEVICES) ============= */

// @media screen and (max-width: 300px) {

// }