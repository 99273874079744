#blogContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .blogPost {
        min-width: 215px;
        width: 40%;
        max-width: 40%;
        box-shadow: var(--effect-dropShadowSubtle);
        margin: 1em;
        padding: 1em 0.5em;

        h2 {
            font-size: 1.3em;
            line-height: 1;
            margin-bottom: 0.25em;
            color: var(--color-primary);
            font-weight: 800;

            a {
                text-decoration: none;

                &:hover {
                    color: var(--color-primary-variant);
                }
            }
        }

        span {
            display: block;
            line-height: 1;
        }

        p.blogBody {
            margin-top: 1em;
        }
    }
}