nav {
  height: 75px;
  background-color: var(--color-primary);
  box-shadow: var(--effect-dropShadow);
  margin-bottom: 10em;
  position: fixed;
  z-index: 1000;
  transition: var(--transition);
}
nav button.hamburger,
nav button.closeMobileMenu {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  height: 100%;
  display: none;
  color: var(--color-white);
}
nav .container {
  height: 100%;
}
nav .brand {
  margin-top: 0.4em;
}
nav .brand img {
  height: 150px;
  border-radius: 0.5em;
  box-shadow: var(--effect-dropShadow);
  transition: var(--transition);
}
nav.scrolled {
  height: 60px;
}
nav.scrolled .brand img {
  height: 100px;
}
nav .navItems {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
nav .navItems li {
  display: flex;
  align-items: center;
  margin-left: 2em;
  height: 100%;
}
nav .navItems li .nav-link {
  color: var(--color-white);
  position: relative;
  font-weight: 600;
}
nav .navItems li .nav-link.active::before {
  position: absolute;
  content: "";
  bottom: -5px;
  width: 100%;
  height: 3px;
  background-color: var(--color-primary-variant);
}
nav .navItems li .nav-link.mobile-only {
  display: none;
  visibility: hidden;
  pointer-events: none;
  height: 0;
  padding: 0 !important;
  margin: 0;
}
nav .navItems:hover {
  cursor: pointer;
}

nav .navItems li.dropdownParent {
  position: relative;
}
nav .navItems li.dropdownParent .nav-link {
  display: flex;
}
nav .navItems li.dropdownParent .nav-link .dropdownIcon {
  display: flex;
  margin-top: 6px;
  margin-left: 0.25em;
}
nav .navItems li.dropdownParent .dropdownMenu {
  position: absolute;
  background-color: var(--color-primary);
  top: -300%;
  opacity: 0%;
  pointer-events: none;
  width: -moz-max-content;
  width: max-content;
  border-top: 3px solid var(--color-primary-variant);
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  box-shadow: var(--effect-dropShadow);
}
nav .navItems li.dropdownParent .dropdownMenu ul {
  padding: 1.25em 1.25em;
}
nav .navItems li.dropdownParent .dropdownMenu ul * + * {
  margin-top: 0.5em;
}
nav .navItems li.dropdownParent .dropdownMenu ul li {
  margin-left: 0;
  border-radius: 0.5em;
  transition: var(--transition);
}
nav .navItems li.dropdownParent .dropdownMenu ul li .nav-link {
  padding: 1em 1.5em;
  width: 100%;
}
nav .navItems li.dropdownParent .dropdownMenu ul li .nav-link.active::before {
  position: absolute;
  content: "";
  bottom: 10px;
  width: calc(100% - 3em);
  height: 3px;
  background-color: var(--color-primary-variant);
}
nav .navItems li.dropdownParent .dropdownMenu ul li:hover {
  background-color: var(--color-primary-hover);
}
nav .navItems li.dropdownParent .dropdownMenu ul li:hover .nav-link {
  color: var(--color-white);
}
nav .navItems li.dropdownParent:hover > .nav-link {
  color: var(--color-light);
}
nav .navItems li.dropdownParent:hover .dropdownMenu {
  opacity: 100%;
  top: 100%;
  pointer-events: all;
  display: unset;
}

/* ============= MEDIA QUERIES (LARGE DEVICES) ============= */
/* ============= MEDIA QUERIES (MEDIUM DEVICES) ============= */
@media screen and (max-width: 1024px) {
  nav {
    display: flex;
    flex-direction: row-reverse;
    padding: 0 0.5em;
  }
  nav.scrolled {
    height: 75px;
  }
  nav.scrolled .brand img {
    height: 100px;
  }
  nav button.hamburger {
    display: unset;
    font-size: 3em;
    display: flex;
    align-items: center;
  }
  nav .navItems {
    position: fixed;
    z-index: 10000;
    top: 0;
    right: 0;
    bottom: 0;
    max-width: 0px;
    height: 100vh;
    background-color: var(--color-primary);
    flex-direction: column;
    align-items: flex-start;
    transition: var(--transition);
  }
  nav .navItems button.closeMobileMenu {
    opacity: 0;
    pointer-events: none;
    display: block;
    height: var(--nav-height);
  }
  nav .navItems li {
    height: unset;
    margin: 1.5em 3em 1.5em 1.5em;
  }
  nav .navItems li.dropdownParent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  nav .navItems li.dropdownParent .dropdownMenu {
    position: static;
    margin-top: 0;
    border: 0px solid var(--color-primary-variant);
    max-height: 0;
    display: flex;
    transition: var(--transition);
  }
  nav .navItems li.dropdownParent .dropdownMenu ul li {
    margin: 0;
  }
  nav .navItems li.dropdownParent .dropdownMenu ul li.nav-link.mobile-only {
    display: block;
    visibility: visible;
    pointer-events: all;
  }
  nav .navItems li.dropdownParent:hover .dropdownMenu, nav .navItems li.dropdownParent:active .dropdownMenu {
    margin-top: 1em;
    opacity: 100%;
    border-top: 3px solid var(--color-primary-variant);
    max-height: 200px;
    pointer-events: all;
    transition: var(--transition);
  }
  nav .navItems.expanded {
    width: 330px;
    max-width: 330px;
  }
  nav .navItems.expanded .closeMobileMenu-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: var(--nav-height);
    padding-right: 0.5em;
  }
  nav .navItems.expanded .closeMobileMenu-wrapper button.closeMobileMenu {
    pointer-events: all;
    opacity: 1;
    font-size: 3em;
    align-self: flex-end;
    justify-content: center;
    transition: opacity 0.5s ease;
    transition-delay: 0.1s;
    height: 100%;
  }
}
/* ============= MEDIA QUERIES (SMALL DEVICES) ============= */
@media screen and (max-width: 600px) {
  nav .brand img {
    height: 100px;
  }
}/*# sourceMappingURL=nav.css.map */