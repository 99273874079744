#applyRoot {
    .blinkWrapper {
        .blinkItem {
            min-width: 280px;
            margin-right: 1em;

            img {
                max-width: 320px;
                border-radius: var(--effect-roundedCorners);
            }
        }
    }
}

/* ============= MEDIA QUERIES (MEDIUM DEVICES) ============= */

@media screen and (max-width: 1024px) {
    #applyRoot {
        .blinkWrapper {
            flex-direction: column;

            .blinkItem {
                img {
                    max-width: unset;
                    width: 100%;
                }
            }
        }
    }
}