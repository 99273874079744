nav {
    height: 75px;
    background-color: var(--color-primary);
    box-shadow: var(--effect-dropShadow);
    margin-bottom: 10em;
    position: fixed;
    z-index: 1000;
    transition: var(--transition);

    button.hamburger,
    button.closeMobileMenu {
        background-color: transparent;
        border: none;
        outline: none;
        padding: 0;
        margin: 0;
        height: 100%;
        display: none;
        color: var(--color-white);
    }

    .container {
        height: 100%;
    }

    .brand {
        margin-top: .4em;

        img {
            height: 150px;
            border-radius: .5em;
            box-shadow: var(--effect-dropShadow);
            transition: var(--transition);
        }
    }

    &.scrolled {
        height: 60px;

        .brand img {
            height: 100px;
        }
    }

    .navItems {
        display: flex;
        align-items: center;
        white-space: nowrap;

        li {
            display: flex;
            align-items: center;
            margin-left: 2em;
            height: 100%;

            .nav-link {
                color: var(--color-white);
                position: relative;
                font-weight: 600;

                &.active::before {
                    position: absolute;
                    content: '';
                    bottom: -5px;
                    width: 100%;
                    height: 3px;
                    background-color: var(--color-primary-variant);
                }

                &.mobile-only {
                    display: none;
                    visibility: hidden;
                    pointer-events: none;
                    height: 0;
                    padding: 0!important;
                    margin: 0;
                }
            }
        }

        &:hover {
            cursor: pointer;
        }
    }
}

nav {
    .navItems {
        li.dropdownParent {
            position: relative;

            .nav-link {
                display: flex;

                .dropdownIcon {
                    display: flex;
                    margin-top: 6px;
                    margin-left: 0.25em;
                }
            }

            .dropdownMenu {
                position: absolute;
                background-color: var(--color-primary);
                top: -300%;
                opacity: 0%;
                pointer-events: none;
                width: max-content;
                border-top: 3px solid var(--color-primary-variant);
                border-bottom-left-radius: .5em;
                border-bottom-right-radius: .5em;
                box-shadow: var(--effect-dropShadow);

                ul {
                    padding: 1.25em 1.25em;

                    * + * {
                        margin-top: .5em;
                    }

                    li {
                        margin-left: 0;
                        border-radius: .5em;
                        transition: var(--transition);

                        .nav-link {
                            padding: 1em 1.5em;
                            width: 100%;

                            &.active::before {
                                position: absolute;
                                content: '';
                                bottom: 10px;
                                width: calc(100% - 3em);
                                height: 3px;
                                background-color: var(--color-primary-variant);
                            }
                        }

                        &:hover {
                            background-color: var(--color-primary-hover);

                            .nav-link {
                                color: var(--color-white);
                            }
                        }
                    }
                }
            }

            &:hover {
                > .nav-link {
                    color: var(--color-light);
                }

                .dropdownMenu {
                    opacity: 100%;
                    top: 100%;
                    pointer-events: all;
                    display: unset;
                }
            }
        }
    }
}

/* ============= MEDIA QUERIES (LARGE DEVICES) ============= */

// @media screen and (max-width: 1200px) {

// }

/* ============= MEDIA QUERIES (MEDIUM DEVICES) ============= */

@media screen and (max-width: 1024px) {
    nav {
        display: flex;
        flex-direction: row-reverse;
        padding: 0 0.5em;

        &.scrolled { // Cancels out the shrinkNav function found in Nav.jsx
            height: 75px;
    
            .brand img {
                height: 100px;
            }
        }

        button.hamburger {
            display: unset;
            font-size: 3em;
            display: flex;
            align-items: center;
        }

        .navItems {
            position: fixed;
            z-index: 10000;
            top: 0;
            right: 0;
            bottom: 0;
            max-width: 0px;
            height: 100vh;
            background-color: var(--color-primary);
            flex-direction: column;
            align-items: flex-start;
            transition: var(--transition);

            button.closeMobileMenu {
                opacity: 0;
                pointer-events: none;
                display: block;
                height: var(--nav-height);
            }

            li {
                height: unset;
                margin: 1.5em 3em 1.5em 1.5em;

                &.dropdownParent {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    .dropdownMenu {
                        position: static;
                        margin-top: 0;
                        border: 0px solid var(--color-primary-variant);
                        max-height: 0;
                        display: flex;
                        transition: var(--transition);

                        ul {
                            li {
                                margin: 0;

                                &.nav-link.mobile-only {
                                    display: block;
                                    visibility: visible;
                                    pointer-events: all;
                                }
                            }
                        }
                    }

                    &:hover,
                    &:active {        
                        .dropdownMenu {
                            margin-top: 1em;
                            opacity: 100%;
                            border-top: 3px solid var(--color-primary-variant);
                            max-height: 200px; // Specifically 200 because it is only slightly larger than the largest .dropdownMenu will ever get. Increase this value to remain ~50px greater than any given .dropdownMenu's max height. Example. this will need to increae if pages are added to this dropdown menu.
                            pointer-events: all;
                            transition: var(--transition);
                        }
                    }
                }
            }

            &.expanded {
                width: 330px;
                max-width: 330px;

                .closeMobileMenu-wrapper {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    width: 100%;
                    height: var(--nav-height);
                    padding-right: 0.5em;

                    button.closeMobileMenu {
                        pointer-events: all;
                        opacity: 1;
                        font-size: 3em;
                        align-self: flex-end;
                        justify-content: center;
                        transition: opacity .5s ease;
                        transition-delay: 0.1s;
                        height: 100%;
                    }
                }
            }
        }
    }
}

/* ============= MEDIA QUERIES (SMALL DEVICES) ============= */

@media screen and (max-width: 600px) {   
    nav {
        .brand {
            img {
                height: 100px;
            }
        }
    }
}