footer {
  background-color: var(--color-dark);
  color: var(--color-white);
}
footer #footerMain {
  padding: 3.5em 0;
  justify-content: space-between;
  align-items: baseline;
  gap: 0.5em;
  min-height: 235px;
  font-size: 14px;
}
footer #footerMain a {
  color: var(--color-primary-variant);
}
footer #footerMain .copyLink {
  margin-left: 1em;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: var(--transition-fast);
}
footer #footerMain .copyLink .copyIcon {
  font-size: 13px;
  margin-right: 0.5em;
  color: var(--color-white);
}
footer #footerMain .copyLink:hover {
  color: var(--color-white);
}
footer #footerMain .recentPosts {
  color: var(--color-primary-variant);
}
footer #footerMain .recentPosts ul {
  list-style-type: none;
  color: var(--color-white);
}
footer #footerMain .recentPosts ul li {
  position: relative;
  padding-left: 14px;
}
footer #footerMain .recentPosts ul li:before {
  position: absolute;
  top: 9px;
  left: 0;
  border-width: 3px;
  border-style: solid;
  border-radius: 3px;
  content: "";
  border-color: var(--color-primary-variant);
}
footer #footerMain .badges {
  align-self: flex-start;
}
footer #footerMain .badges img {
  border-radius: var(--effect-roundedCorners);
}
footer #footerMain .nmls p {
  line-height: 1.3;
}
footer #footerSocial {
  height: 60px;
  padding: 0.25em 2em;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--color-darker);
  border-top-left-radius: var(--effect-roundedCorners);
  border-top-right-radius: var(--effect-roundedCorners);
  font-size: 2em;
}
footer #footerSocial a {
  color: var(--color-text-light);
}
footer #footerSocial * {
  margin-bottom: -0.15em;
  margin-left: 0.25em;
  transition: var(--transition-fast);
}
footer #footerSocial *:hover {
  color: var(--color-white);
}

/* ============= MEDIA QUERIES (MEDIUM DEVICES) ============= */
@media screen and (max-width: 1024px) {
  footer #footerMain {
    flex-direction: column;
    align-items: center;
    gap: 2.5em;
  }
  footer #footerMain * {
    text-align: center;
  }
  footer #footerMain .badges {
    align-self: center;
  }
}
/* ============= MEDIA QUERIES (SMALL DEVICES) ============= */
@media screen and (max-width: 600px) {
  footer #footerSocial {
    height: 130px;
    align-items: flex-start;
  }
  footer #footerSocial * {
    margin-bottom: 0;
  }
}
/* ============= MEDIA QUERIES (EXTRA SMALL DEVICES) ============= */
@media screen and (max-width: 300px) {
  footer #footerMain .phone,
  footer #footerMain .email {
    flex-direction: column;
    align-items: center;
  }
}/*# sourceMappingURL=footer.css.map */