#homeRoot {
    height: 100vh;
}

.home-background {
    background-image: url("https://integra.imgix.net/integra_house1_max.jpg?auto=compress&h=1080");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: -10;

    .bg-overlay {
        background-color: #000;
        opacity: .5;
        height: 100%;
        width: 100%;
    }
}

#homeTitle {
    padding-top: calc(150px + 0.4em);
    height: 100%;
    color: var(--color-white);

    h1 {
        font-weight: 600;
        font-size: clamp(28px, 5vw, 45px);
    }

    h2 {
        width: 75%;
        font-size: clamp(16px, 5vw, 20px);
        font-style: italic;
    }

    .cta-wrapper {
        display: flex;
        margin-top: 1.5em;

        * + * {
            margin-left: 1em;
        }

        button {
            display: flex;
            align-items: center;

            svg {
                font-size: 0;
                margin-left: 0;
                transition: var(--transition);
            }

            &:hover {
                svg {
                    font-size: 1.5em;
                    margin-left: .25em;
                }
            }
        }
    }
}

#homeScrollIndicator {
    padding: 2em;

    a.scrollDown {
        line-height: 0%;
    }
}

#homeInfo {
    padding: 4em 0;

    .text-block {
        text-align: center;

        h1 {
            font-size: 1.375em;
            margin-bottom: 0.5em;
        }

        p {
            font-size: 0.875em;
        }
    }
    
    .card-wrapper {
        margin-top: 4em;
        
        h2 {
            font-weight: 500;
        }

        .btn a {
            color: white;
        }
    }

    // .card-wrapper {
    //     width: 100%;
    //     margin: 0px;
    //     margin-top: 4em;
    //     gap: 1em;

    //     .card {
    //         background-color: var(--color-primary);
    //         text-align: center;
    //         color: var(--color-white);
    //         padding: 2em;
    //         border-radius: var(--effect-roundedCorners);
    //         box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
    //         display: flex;
    //         flex-direction: column;
    //         justify-content: space-between;
    //         align-items: center;

    //         .cta-id {
    //             margin-bottom: 2em;

    //             h1 {
    //                 font-size: 1.4em;
    //             }
    
    //             h2 {
    //                 font-size: 0.875em;
    //             }
    //         }
    //     }
    // }
}

/* ============= MEDIA QUERIES (MEDIUM DEVICES) ============= */

@media screen and (max-width: 1024px) {
    #homeTitle {    
        h1 {
            font-weight: 600;
        }
    
        h2 {
            font-style: italic;
        }
    
        .cta-wrapper {
            display: flex;
            flex-direction: column;
    
            * + * {
                margin-left: 0;
                margin-top: 0.75em;
            }
    
            button {
                display: flex;
                align-items: center;
    
                svg {
                    font-size: 0;
                    margin-left: 0;
                    transition: var(--transition);
                }
    
                &:hover {
                    svg {
                        font-size: 1.5em;
                        margin-left: .25em;
                    }
                }
            }
        }
    }

    #homeScrollIndicator {
        margin-bottom: 4em;
    }
}

/* ============= MEDIA QUERIES (SMALL DEVICES) ============= */

@media screen and (max-width: 600px) {
    #homeTitle {
    
        h2 {
            width: 100%;
        }
    }
}