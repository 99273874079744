#calculatorRoot .calculator-wrapper form {
  margin: 0 2em;
}
#calculatorRoot .calculator-wrapper form .form-item {
  margin: 1em 0;
}
#calculatorRoot .calculator-wrapper form .form-item label {
  font-weight: 600;
  font-size: 1.25em;
}
#calculatorRoot .calculator-wrapper form .form-item .form-input input {
  padding: 1em;
  border-radius: var(--effect-roundedCorners);
  box-shadow: var(--effect-dropShadowSubtle);
}
#calculatorRoot .calculator-wrapper form .form-action {
  margin: 1em 0;
}
#calculatorRoot .calculator-wrapper form .form-action input {
  padding: 1em;
  background-color: var(--color-primary-variant);
  border-radius: var(--effect-roundedCorners);
  box-shadow: var(--effect-dropShadowSubtle);
  font-size: 1em;
  font-weight: 600;
  color: var(--color-white);
  font-family: "Poppins", sans-serif;
  transition: var(--transition-fast);
}
#calculatorRoot .calculator-wrapper form .form-action input:hover {
  cursor: pointer;
  background-color: var(--color-primary-light);
  color: var(--color-text-black);
}
#calculatorRoot .calculator-wrapper .estimatedPayment {
  background-color: var(--color-primary-variant);
  margin: 4em;
  padding: 1em;
  text-align: center;
  border-radius: var(--effect-roundedCorners);
  box-shadow: var(--effect-dropShadowSubtle);
  min-width: 320px;
}
#calculatorRoot .calculator-wrapper .estimatedPayment span {
  color: white;
  font-size: 2.5em;
}
#calculatorRoot .calculator-wrapper .table-wrapper {
  overflow-x: auto;
  margin-top: 2em;
}
#calculatorRoot .calculator-wrapper table {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--color-primary-light);
  border-radius: var(--effect-roundedCorners);
  box-shadow: var(--effect-dropShadowSubtle);
}
#calculatorRoot .calculator-wrapper table thead tr th {
  padding: 1em 1em;
}
#calculatorRoot .calculator-wrapper table tbody tr {
  border-bottom: 1px solid var(--color-border-grey);
  text-align: center;
}
#calculatorRoot .calculator-wrapper table tbody tr td {
  padding: 1em 0;
}

/* ============= MEDIA QUERIES (LARGE DEVICES) ============= */
@media screen and (max-width: 1200px) {
  #calculatorRoot .calculator-wrapper .estimatedPayment {
    margin: 4em 2em;
    min-width: 280px;
  }
  #calculatorRoot .calculator-wrapper .estimatedPayment span {
    font-size: 2.5em;
  }
}
/* ============= MEDIA QUERIES (MEDIUM DEVICES) ============= */
@media screen and (max-width: 1024px) {
  #calculatorRoot .calculator-wrapper form {
    margin: 0 1em 0 0;
  }
  #calculatorRoot .calculator-wrapper .estimatedPayment {
    margin: 4em 0em;
  }
  #calculatorRoot .calculator-wrapper .estimatedPayment span {
    font-size: 1.75em;
  }
}
/* ============= MEDIA QUERIES (INTERMEDIATE DEVICES) ============= */
@media screen and (max-width: 800px) {
  #calculatorRoot .calculator-wrapper form {
    margin: 0 2em;
  }
  #calculatorRoot .calculator-wrapper .estimatedPayment {
    min-width: 250px;
  }
}
/* ============= MEDIA QUERIES (SMALL DEVICES) ============= */
@media screen and (max-width: 600px) {
  #calculatorRoot .calculator-wrapper .form-wrapper {
    flex-direction: column;
  }
  #calculatorRoot .calculator-wrapper .form-wrapper form {
    margin: 0;
  }
}
/* ============= MEDIA QUERIES (EXTRA SMALL DEVICES) ============= *//*# sourceMappingURL=calculator.css.map */