#testimonialRoot section.cardWrapper {
  all: unset;
}
#testimonialRoot section.cardWrapper article.card {
  margin-bottom: 2em;
  padding: 2em;
  border-radius: var(--effect-roundedCorners);
  background-color: var(--color-primary-light);
  box-shadow: var(--effect-dropShadowSubtle);
}
#testimonialRoot section.cardWrapper article.card h3.header {
  font-size: 1.75em;
  font-weight: 700;
  line-height: 1;
  background: url(https://integra.imgix.net/quotes.png) no-repeat scroll left center transparent;
  padding-left: 40px;
}
#testimonialRoot section.cardWrapper article.card .meta {
  line-height: 1;
  font-size: 14px;
  color: var(--color-text-lighter);
  margin: 0.5em 0;
}
#testimonialRoot section.cardWrapper article.card .meta * {
  display: block;
}
#testimonialRoot section.cardWrapper article.card .content {
  margin: 1em 0;
  font-size: 14px;
}
#testimonialRoot ul li::before {
  all: unset;
}

/* ============= MEDIA QUERIES (SMALL DEVICES) ============= */
@media screen and (max-width: 600px) {
  #testimonialRoot p img {
    float: none;
    margin-right: 0;
    width: 100%;
    max-height: 200px;
    -o-object-fit: cover;
       object-fit: cover;
    margin-bottom: 2em;
  }
  #testimonialContent {
    display: flex;
    flex-direction: column;
  }
}/*# sourceMappingURL=testimonials.css.map */