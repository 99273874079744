.sidebar {
  border-left: 1px solid var(--color-border-grey);
  margin-left: 2em;
  padding-left: 2em;
  min-width: 230px;
  font-size: 14px;
}
.sidebar .fb-page {
  z-index: -1;
}
.sidebar p {
  margin: 0;
}
.sidebar a:hover {
  color: var(--color-primary-variant);
}
.sidebar .sb-social {
  margin: 6em 0;
}
.sidebar .sb-social a {
  margin-left: 0.5em;
}
.sidebar .sb-social .sb-social-vendor {
  margin: 1em 0;
}

/* ============= MEDIA QUERIES (INTERMEDIATE DEVICES) ============= */
@media screen and (max-width: 800px) {
  .sidebar {
    border-left: none;
    border-top: 1px solid var(--color-border-grey);
    margin-top: 2em;
    padding-top: 2em;
    margin-left: 0;
    padding-left: 0;
  }
  .sidebar .sb-social {
    margin: 2em 0;
  }
}/*# sourceMappingURL=sidebar.css.map */