@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bg: hsl(0, 0%, 100%);
    --color-bg-variant: hsl(0, 0%, 95%);
    --color-primary: hsl(173, 89%, 25%);
    --color-primary-hover: hsl(173, 89%, 21%);
    --color-primary-variant: #7EBEC5;
    --color-primary-light: hsl(173, 89%, 97%);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);
    --color-dark: #2e2e2e;
    --color-darker: #1F1F1F;

    --color-text-black: #333333;
    --color-text-light: #636363;
    --color-text-lighter: #979797;

    --color-border-grey: #dedede;

    --effect-dropShadow: 0 2px 12px rgba(0, 0, 0, 0.4);
    --effect-dropShadowSubtle: 0 8px 16px rgba(0, 0, 0, 0.075);
    --effect-roundedCorners: 0.75em;

    --container-width-max: 100%;
    --container-width-xl: 70%;
    --container-width-lg: 84%;
    --container-width-md: 92%;
    --container-width-sm: 97%;

    --nav-height: 75px;
    --footer-height: 295px;

    --transition: all 400ms ease;
    --transition-fast: all 200ms ease;
}

html {
    scroll-behavior: smooth;
}

body, button {
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
    color: var(--color-text-black);
    line-height: 1.7;
    overflow-x: hidden;
}

/* ============= GENERAL STYLES ============= */

.moduleOverlay {
    position: fixed;
    z-index: 9000;
    top: 0;
    left: 0;
    width: 0%;
    height: 100vh;
    pointer-events: none;
    transition: var(--transition);

    &.enabled {
        width: calc(100% - 330px);
        background-color: rgba($color: #000000, $alpha: .5);
        pointer-events: all;
    }
}

.container {
    width: var(--container-width-xl);
    max-width: 1200px;
    margin: 0 auto;
}

.container-max {
    width: var(--container-width-max);
}

.flex {
    display: flex;

    &.flex-column {
        flex-direction: column;
    }

    &.flex-jc-c {
        justify-content: center;
    }

    &.flex-jc-sb {
        justify-content: space-between;
    }

    &.flex-ai-c {
        align-items: center;
    }

    &.flex-ai-bl {
        align-items: baseline;
    }

    &.flex-as-c {
        align-self: center;
    }

    &.flex-ai-fe {
        align-items: flex-end;
    }

    &.flex-wrap {
        flex-wrap: wrap;
    }
}

.nobreak {
    white-space: nowrap;
}

.bold {
    font-weight: 600;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 600;
}

h1 {
    font-size: 2.5rem;
}

section {
    min-height: calc(100vh - var(--footer-height));
    padding-top: calc(var(--nav-height) + 7em);
    padding-bottom: 3em;
    display: flex;

    h1 {
        margin-bottom: 1em;
    }

    p {
        margin-bottom: 2em;
    }

    ul {
        margin-bottom: 2em;

        li {
            position: relative;
            padding-left: 1em;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                height: .35em;
                width: .35em;
                border-radius: 1em;
                background-color: #000000;
            }
        }
    }
}

section {
    margin-top: 8rem;
}

section > h2,
section > h5 {
    text-align: center;
    color: var(--color-light);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light {
    color: var(--color-text-light);
}

.text-bold {
    font-weight: 600;
}

a {
    color: var(--color-primary);
    transition: var(--transition-fast);

    &:hover {
        color: var(--color-primary-variant)
    }
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.5em 1em;
    border-radius: 0.3em;
    border: 2px solid var(--color-white);
    transition: var(--transition);
    cursor: pointer;
    font-size: 1.25em;
}

.btn-primary {
    background-color: var(--color-primary);
    color: var(--color-bg);
    box-shadow: var(--effect-dropShadow);
}

.btn-cta {
    background-color: var(--color-primary-variant);
    color: var(--color-white);
}

// img {
//     display: block;
//     width: 100%;
//     object-fit: cover;
// }

/* ============= MEDIA QUERIES (LARGE DEVICES) ============= */

@media screen and (max-width: 1200px) {
    .container {
        width: var(--container-width-lg);
    }

    .section {
        margin-top: 6rem;
    }
}

/* ============= MEDIA QUERIES (MEDIUM DEVICES) ============= */

@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }

    .section {
        margin-top: 6rem;
    }
}

/* ============= MEDIA QUERIES (INTERMEDIATE DEVICES) ============= */

@media screen and (max-width: 800px) {
    section {
        flex-direction: column;
    }
}

/* ============= MEDIA QUERIES (SMALL DEVICES) ============= */

@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
    }

    .section > h2{
        margin-bottom: 2rem;
    }

    .btn {
        padding: 0.25em .75em;
        font-size: 1.15em;
    }

    section {
        padding-top: calc(var(--nav-height) + 5em);
        font-size: 14px;

        h1 {
            font-size: 2em;
        }
    }
}



/* ==================================== */
/* ==================================== */
/* = COPY&PASTE MEDIA QUERY TEMPLATES = */
/* ==================================== */
/* ==================================== */

/* ============= MEDIA QUERIES (LARGE DEVICES) ============= */

// @media screen and (max-width: 1200px) {

// }

/* ============= MEDIA QUERIES (MEDIUM DEVICES) ============= */

// @media screen and (max-width: 1024px) {

// }

/* ============= MEDIA QUERIES (INTERMEDIATE DEVICES) ============= */

// @media screen and (max-width: 800px) {

// }

/* ============= MEDIA QUERIES (SMALL DEVICES) ============= */

// @media screen and (max-width: 600px) {

// }

/* ============= MEDIA QUERIES (EXTRA SMALL DEVICES) ============= */

// @media screen and (max-width: 300px) {

// }