form#contact-form {
  display: flex;
  flex-direction: column;
}
form#contact-form label {
  font-size: 1em;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 3px 0 15px 3px;
  font-weight: 700;
}
form#contact-form input,
form#contact-form textarea {
  margin-bottom: 1.5rem;
  padding: 1.5em;
  border: none;
  outline: 0;
  background: #fff;
  box-shadow: 0 2px 10px 0 rgba(14, 30, 37, 0.12);
  border-radius: 10px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
form#contact-form textarea {
  min-height: 100px;
}
form#contact-form button[type=submit] {
  font-weight: 800;
  font-size: 1.25em;
  text-transform: uppercase;
  padding: 0.5em 1em;
  border-radius: 10px;
  background-color: var(--color-primary);
  color: var(--color-white);
}
form#contact-form button[type=submit]:hover {
  cursor: pointer;
}
form#contact-form .hidden {
  display: none;
}
form#contact-form .form-row {
  display: flex;
  flex-direction: column;
}
form#contact-form .form-row.split {
  flex-direction: row;
  gap: 2em;
}
form#contact-form .form-row.split .form-element {
  width: 50%;
}
form#contact-form .form-row.shrink {
  width: -moz-min-content;
  width: min-content;
}

/* ============= MEDIA QUERIES (SMALL DEVICES) ============= */
@media screen and (max-width: 600px) {
  form#contact-form .form-row.split {
    flex-direction: column;
    gap: 0;
  }
  form#contact-form .form-row.split .form-element {
    width: unset;
  }
}/*# sourceMappingURL=contact-form.css.map */