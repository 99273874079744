#blogContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
#blogContainer .blogPost {
  min-width: 215px;
  width: 40%;
  max-width: 40%;
  box-shadow: var(--effect-dropShadowSubtle);
  margin: 1em;
  padding: 1em 0.5em;
}
#blogContainer .blogPost h2 {
  font-size: 1.3em;
  line-height: 1;
  margin-bottom: 0.25em;
  color: var(--color-primary);
  font-weight: 800;
}
#blogContainer .blogPost h2 a {
  text-decoration: none;
}
#blogContainer .blogPost h2 a:hover {
  color: var(--color-primary-variant);
}
#blogContainer .blogPost span {
  display: block;
  line-height: 1;
}
#blogContainer .blogPost p.blogBody {
  margin-top: 1em;
}/*# sourceMappingURL=blog.css.map */